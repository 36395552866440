<template>
    <!-- 兑换记录列表 -->
    <div class="exchange grey-bg">
        <!--    搜索订单开始-->
        <div class="target-search align-center" v-show="activeName === 'sell' || activeName === 'buy' ? true : false">
            <!-- <div class="target-search-input align-center">
                <img src="~assets/images/user/search.png" class="search-search"/>
                <input class="search-input" v-model.trim="keywords" auto-focus placeholder="搜索商品" placeholder-class="placeholder-class" @blur="searchOrder" maxlength="20"/>
                <img src="~assets/images/user/cancel.png" class="search-cancel" @click="keywords=''"/>
            </div> -->
            <van-search v-model="keywords" placeholder="请输入搜索关键词" show-action  @search="searchOrder" @cancel="onCancel" style="width:100%" />
            <!-- <div class="cancel-text"  @click="cancelSearch">取消</div> -->
        </div>
        <!--    搜索订单开始-->

        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="(sellOrder.length > 5 || buyOrder.length > 5) ? finishedText:''"
            @load="onLoad"
        >
            <div class="exchange-tab-con">
                <van-tabs   v-model:active="activeName" bind:change="onChange" class="exchange-tab" title-active-color="#FF6B29"
                           @click="clickTab" :id="activeName == 'create' ? 'tabs':''"
                           title-inactive-color="#4D4D4D" line-width="70" line-height="3">
                    <van-tab title="我卖出的" name="sell" :dot="sellCount != 0">
                        <!--兑换订单开始-->
                        <NoData v-if="sellOrder.length === 0" :bottomBg="true" class="nod"></NoData>
                        <!-- <div class="exchange-order" v-else>
                            <div class="order-con" v-for="order in orderList" :key="order.id" @click="goShow( order && order.id)">
                                <div class="order-item">
                                    <div class="order-number">
                                        <span class="order-number-title">订单编号：</span>
                                        <span class="order-number-num">{{ order && order.no }}</span>
                                    </div>
                                    <div class="order-state deliver" v-if="order.status === 1">已完成</div>
                                    <div class="order-state deliver" v-else-if="order.status === -1">已关闭</div>
                                    <div class="order-state" v-else-if="order.status === 2">待收货</div>
                                    <div class="order-state" v-else>待发货</div>
                                </div>
                                <div class="commodity">
                                    <img class="commodity-img" :src="order.goods && (order.goods.cover_picture)"/>
                                    <div class="commodity-title">
                                        <div class="commodity-name white-space-2">{{ order.goods && order.goods.title }}</div>
                                        <div class="commodity-price">{{ $handle.tranNumber(order.goods.credit,3) }}积分</div>
                                    </div>
                                </div>
                                <div class="order-date vertical-center">
                                    <div class="order-date-time">{{ order && order.created_at }}</div>
                                    <div class="order-date-state" v-if="order.status && (order.status === 1 || order.status === -1 ? false : true)" @click.stop="openOverlay(order.id)">{{ order.status && (order.status === 1 || order.status === -1 ? '' : '确认收货')}}</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="now-list">
                <div class="now-item" v-for="(item,index) in sellOrder" :key="index"    @click="goShow(item.id,item.type,'0')" >
                    <div class="status" v-if="item.status == 0">待发货</div>
                    <div class="status" v-if="item.status == 1">已完成</div>
                    <div class="status" v-if="item.status == 2">已发货</div>
                    <div class="status" v-if="item.status == -1">已关闭</div>
                    <user-info :wechat="item.user_profile && item.user_profile.wechat"  :labelimg="item.user_profile && item.user_profile.user_label && item.user_profile.user_label.picture" :vipimg="item.user_profile && item.user_profile.user && item.user_profile.user.level" :captain="item.user_profile && item.user_profile.is_team_master" :captain_s="item.user_profile && item.user_profile.is_group_team_master"></user-info>
                    <van-divider />
                    <div class="now-item-t">
                        <img class="now-item-t-l" :src="item.goods && item.goods.picture[0]" />
                        <div class="now-item-t-r">
                            <div class="now-item-t-r-t white-space-2">{{item.goods.title}}</div>
                            <div class="now-item-t-r-s vertical-center"><div class="now-item-t-r-s-t">实收</div><span>{{item.credit}}</span>积分</div>
                        </div>
                    </div>
                    <van-divider />
                    <div class="now-item-b vertical-center">
                        <div class="now-item-b-l" @click.stop="goNewsitem.user_profile.user_id()" >
                        <img  src="~assets/images/index/talks.png" class="now-item-b-l-img" alt="" @click.stop="goNews()"/>
                        联系买家</div>
                        <div class="now-item-b-r">
                            <div class="now-item-b-r-btn"  v-if="item.status == 1 || item.status == -1" @click.stop="del(item.id)" >删除</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 0" @click.stop="goDeliver(item.id)"  >去发货</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 2" @click.stop="goDeliver(item.id)" >修改物流</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 2" @click.stop="logistics(item.id)" >查看物流</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 0" @click.stop="ordercancle(item.id)" >取消订单</div>
                            <!-- <div class="now-item-b-r-btn-a" wx:if="{{item.status == 0}}" catchtap="goDeliver">修改物流</div> -->
                        </div>
                    </div>
                </div>
            </div>
                        <!--兑换订单结束--></van-tab>
                         <van-tab title="我买到的" name='buy'  :dot="buyCount != 0">
            <NoData v-if="buyOrder.length === 0"  :bottomBg="true"  class="nod"></NoData>
            <div class="now-list">
                <div class="now-item" v-for="(item,index) in buyOrder" :key="index" @click="goShow(item.id,item.type,'1')" >
                    <div class="status" v-if="item.status == 0">待发货</div>
                    <div class="status" v-if="item.status == 1">已完成</div>
                    <div class="status" v-if="item.status == 2">已发货</div>
                    <div class="status" v-if="item.status == -1">已关闭</div>
                    <user-info v-if="item.user_profile" :wechat="item.user_profile && item.user_profile.wechat"  :labelimg="item.user_profile && item.user_profile.user_label && item.user_profile.user_label.picture" :vipimg="item.user_profile && item.user_profile.user && item.user_profile.user.level" :captain="item.user_profile && item.user_profile.is_team_master" :captain_s="item.user_profile && item.user_profile.is_group_team_master"></user-info>
                    <div v-if="!item.user_profile" class="mall vertical-center">
                        <img class="mall-img" src="~assets/images/index/mall.png"/>
                        <div class="mall-name">商城</div>
                    </div>
                    <van-divider />
                    <div class="now-item-t">
                        <img class="now-item-t-l" v-if="item.type == 'fleas'" :src="item.goods && item.goods.picture[0]" />
                        <img class="now-item-t-l" v-else :src="item.goods && item.goods.picture" />
                        <div class="now-item-t-r">
                            <div class="now-item-t-r-t white-space-2">{{item.goods && item.goods.title}}</div>
                            <div class="now-item-t-r-s vertical-center"><div class="now-item-t-r-s-t">实付</div><span>{{item.credit}}</span>积分</div>
                        </div>
                    </div>
                    <van-divider />
                    <div class="now-item-b vertical-center">
                        <div v-if="item.user_profile" class="now-item-b-l"  @click.stop="goNews()" >
                        <img src="~assets/images/index/talks.png" class="now-item-b-l-img" alt=""/>
                        联系卖家</div>
                        <div v-if="!item.user_profile" class="now-item-b-l">{{item.created_at}}</div>
                        <div class="now-item-b-r">
                            <div class="now-item-b-r-btn"  v-if="item.status == 1 || item.status == -1" @click.stop="del(item.id)" >删除</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 0 && item.type == 'fleas'" @click.stop="ordercancle(item.id)" >取消订单</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 2" @click.stop="openOverlay(item.id,item.type)" >确认收货</div>
                            <div class="now-item-b-r-btn-a" v-if="item.status == 2" @click.stop="logistics(item.id)">查看物流</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-tab>
                    <van-tab title="交易记录" name="create">
                        <NoData v-if="orderCreditList.length === 0" :bottomBg="true"></NoData>
                        <!--积分明细开始-->
                        <div class="points-exchange">
                            <div class="integral-item" v-for="item in orderCreditList" :key="item">
                                <div class="integral-title space-between">
                                    <span class="integral-state white-space">{{ item.remark.action }}</span>
                                    <span class="integral-num" style="color:#22AC38" v-if="item.mode=='reduce'">-{{ $handle.tranNumber( item.amount,3) }}积分</span>
                                    <span class="integral-num" v-if="item.mode=='increase'">+{{ $handle.tranNumber( item.amount,3) }}积分</span>
                                    <!--consumption-->
                                </div>
                                <div class="exchange-date space-between">
                                    <span class="exchange-time">{{ item && item.created_at }}</span>
                                    <span class="exchange-all">剩余{{ item && item.total_amount }}积分</span>
                                </div>
                            </div>
                        </div>
                        <!--积分明细结束-->
                    </van-tab>
                </van-tabs>
            </div>
        </van-list>
        <!--确认收货提示开始-->
        <van-overlay :show="isShowOverlay" @click="show = false" >
            <div class="overlay-receiving-goods-con">
                <div class="overlay-receiving-goods">
                    <div class="overlay-goods-head">
                        <img class="overlay-goods-head-img" src="~assets/images/user/overlay-cancel.png" @click="isShowOverlay = false"/>
                    </div>
                    <div class="overlay-goods-body">
                        <img class="overlay-goods-tip-img" src="~assets/images/user/overlay-tip.png"/>
                        <div class="overlay-goods-tip-text">请收到货后，再确认收货！</div>
                        <div class="overlay-btn">
                            <div class="cancel" @click="isShowOverlay = false">取消</div>
                            <div class="confirm" @click="clickConfirm">确认收货</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
        <!--确认收货提示结束-->
        <!--确认收货提示结束-->
    </div>
</template>

<script>
import NoData from "components/NoData";
import UserInfo from "components/userInfo";
export default {
name: "record",
    data(){
        return{
            activeName: 'sell', //当前选中的tab
            page:1,                 //页码
            keywords: '',           //输入框的文字
            orderList:[],           //订单列表
            orderCreditList: [],    //积分列表
            loading: false,
            finished: false,
            isShowOverlay: false,
            order_id: 0,    //订单id
            finishedText: "没有更多了",
            buyOrder:[],
            sellOrder:[],
            show:true,
            buyCount:0,
            sellCount:0,
        }
    },
    created() {
        if(this.$store.getters.act == 0){
            this.activeName = 'sell'
        }else if(this.$store.getters.act == 1){
            this.activeName = 'buy'
        }else{
            this.activeName = 'sell'
        }
         this.$store.commit('M_ACT','0')
         this.page = 1
        // this.getOrder(this.keywords)
        // this.sellOrderlist()
        // this.buyOrderlist()
        this.$route.meta.backUrl = '/user';
        // this.getOrderCredit()
        this.communityordercount()
    },
    methods:{
        reset(){
        this.buyOrder=[]
            this.sellOrder=[]
            this.page= 1,
        this.sellOrderlist()
        this.buyOrderlist()
      },
               //未读数量
 communityordercount(){
    let url = this.$api.communityordercount 
     this.$http.get(url, true).then(res => {
      if(res.data.success){
        this.buyCount = res.data.data.buy_count
        this.sellCount = res.data.data.sell_count
      }
    })
    },
      sellOrderlist(keywords){
        let _this = this
        let url 
        if (keywords) {
            url = _this.$api.communityFleasearch + '?type=sell&page=' + this.page + '&keyword=' + keywords
              this.$http.get(url,true).then( res => {
            if (res.data.success) {
                 _this.loading = false;
                if(res.data.data.data.length){
                    _this.finished = false;
                    _this.sellOrder= _this.sellOrder.concat(res.data.data.data)
                    _this.page ++
                    _this.communityordercount()
                }else {
                        _this.finished = true;
                    }
            }
        })
        }else{
           url = _this.$api.communityFleaOrder + '?select_status=sell&page=' + this.page
             this.$http.get(url,true).then( res => {
            if (res.data.success) {
                 _this.loading = false;
                if(res.data.data.length){
                    _this.finished = false;
                    _this.sellOrder= _this.sellOrder.concat(res.data.data)
                    _this.page ++
                     _this.communityordercount()
                }else {
                        _this.finished = true;
                    }
            }
        })
        }
      
      },
      buyOrderlist(keywords){
        let _this = this
        let url
        if (keywords) {
            url = this.$api.communityFleasearch + '?type=buy&page=' + this.page + '&keyword=' + keywords
             this.$http.get(url,true).then( res => {
            if (res.data.success) {
                 _this.loading = false;
                if(res.data.data.data.length){
                    _this.finished = false;
                    _this.buyOrder= _this.buyOrder.concat(res.data.data.data)
                    _this.page ++
                     _this.communityordercount()
                }else {
                        _this.finished = true;
                    }
            }
        })
        }else{
            url = this.$api.communityFleaOrder + '?select_status=buy&page=' + this.page
             this.$http.get(url,true).then( res => {
            if (res.data.success) {
                 _this.loading = false;
                if(res.data.data.length){
                    _this.finished = false;
                    _this.buyOrder= _this.buyOrder.concat(res.data.data)
                      _this.page ++
                       _this.communityordercount()
                }else {
                        _this.finished = true;
                    }
            }
        })
        }
      
      },
      //删除
      del(id){
        this.$dialog.confirm({
            title: '删除',
            message: '是否删除该订单',
          })
            .then(() => {
              // on confirm
              let url = this.$api.communityFleaOrder + '/' + id
              this.$http.del(url,{},true).then( res => {
                  if (res.data.success) {
                      this.$toast('删除成功');
                      this.reset()
                  }
              })
            })
            .catch(() => {
              // on cancel
            });
       
      },
      //取消订单
      ordercancle(id){
        this.$dialog.confirm({
            title: '取消',
            message: '是否取消该订单',
          })
            .then(() => {
              // on confirm
              let url = this.$api.communityFleaCancel + '/' + id
              this.$http.put(url,{},true).then( res => {
                  if (res.data.success) {
                     this.$toast('取消成功');
                      this.reset()
                  }
              })
            })
            .catch(() => {
              // on cancel
            });
       
      },
       goDeliver(id) {
        this.$router.push({path: '/user/order/deliver',query: {id: id,type:'flea'}})
    },
      //  查看物流
     logistics(id){
          this.$router.push({path: '/order/record/logistics/'+id})
        // this.$router.push({path: `/order/record/logistics/${this.orderShow.id}`})
      },
        goNews(){
          this.$router.push('/user/im')
        },

        //获取兑换订单
        // getOrder(keywords){
        //     let _this = this
        //     let url = _this.$api.Order +'?page=' + this.page
        //     if (keywords){
        //         url = _this.$api.Order +'?page=' + this.page+'&keywords=' + keywords
        //     }
        //     _this.finished = true;
        //     _this.$http.get(url,true).then( res => {
        //         if(res.data.success){
        //             this.loading = false;
        //             if(res.data.data.data.length){
        //                 this.finished = false;
        //                 _this.orderList = _this.orderList.concat(res.data.data.data)
        //                 _this.finishedText = '没有更多了'
        //             }else {
        //                 this.finished = true;
        //                 if(_this.keywords.length === 0 && _this.orderList.length === 0){
        //                     _this.finishedText = ''
        //                 }
        //             }
        //         }
        //     } )
        // },
        //积分明细
        getOrderCredit(){
            let _this = this
            let url = _this.$api.OrderCredit +'?page=' + this.page;
            _this.finished = true;

            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    _this.loading = false;
                    if(res.data.data.length){
                        _this.finished = false;
                        _this.orderCreditList =  _this.orderCreditList.concat(res.data.data)
                        _this.page ++
                    }else {
                        _this.finished = true;
                    }
                }
            } )
        },
        // 切换tab
        clickTab(){
            this.page = 1
            this.buyOrder=[],
            this.sellOrder=[],
            this.orderCreditList = []
            if(this.activeName === 'sell'){
                // this.getOrder(this.keywords)
                this.sellOrderlist(this.keywords)
            }
            else if(this.activeName === 'create'){
                this.getOrderCredit()
            }
            else if(this.activeName === 'buy'){
                this.buyOrderlist(this.keywords)
                 this.$forceUpdate();
            }
        },
        onLoad(){
            if(this.activeName === 'sell'){
                this.sellOrderlist(this.keywords)
            }  else if(this.activeName === 'create'){
                this.getOrderCredit()
            }
            else if(this.activeName === 'buy'){
                this.buyOrderlist(this.keywords)
            }
        },
        //取消搜索
        cancelSearch(){
          this.keywords = ''
        //   this.getOrder(this.keywords)
        this.sellOrderlist(this.keywords)
        this.buyOrderlist(this.keywords)
        },
        //搜索订单
        searchOrder(){
            this.buyOrder = []
            this.sellOrder = []
            this.page = 1
            if(this.keywords == ''){
                this.show = true
            }else{
                this.show = false
            }
            // this.getOrder(this.keywords.trim())
            this.sellOrderlist(this.keywords.trim())
        this.buyOrderlist(this.keywords.trim())
        },
        //确认收货
        openOverlay(id,type){
            this.isShowOverlay = true
            this.order_id = id
            this.type = type
        },
        // 确认收货弹框
        clickConfirm(){
            let _this = this
            if(this.type == 'fleas'){
  let url = _this.$api.communityFleareceipt+ '/' + _this.order_id
            let data = {
                id: _this.order_id
            }
            _this.page = 1
            _this.orderList = []
            _this.finished = true;
            _this.$http.put(url,data,true).then( res => {
                if(res.data.success){
                    this.buyOrder = []
            this.sellOrder = []
            this.page = 1
                    _this.isShowOverlay = false
                    // _this.getOrder()
                    _this.sellOrderlist()
                    _this.buyOrderlist()
                }
            } )
            }else{
                 let url = _this.$api.OrderUpdate
            let data = {
                id: _this.order_id
            }
              _this.page = 1
            _this.orderList = []
            _this.finished = true;
            _this.$http.post(url,data,true).then( res => {
                if(res.data.success){
                         this.buyOrder = []
            this.sellOrder = []
            this.page = 1
                    _this.isShowOverlay = false
                    // _this.getOrder()
                    _this.sellOrderlist()
                    _this.buyOrderlist()
                }
            } )
            }
          
        },
        goShow(id,type,act) {
            this.$store.commit('M_ACT',act)
            if(type == 'fleas'){
                 this.$router.push({path:'/order/record/show/' + id,query: {type:'fleas'}})
            }else{
              this.$router.push('/order/record/show/' + id + '?type=mall')  
            }
           
        },
        onCancel(){
            this.$router.push({path: '/user'})
        }
    },
    components:{
        NoData,
        UserInfo
    }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/record/index.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.exchange .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 182/$r;
    z-index: 99;
  }
  .exchange #tabs .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
 .exchange  .target-search{
     position: fixed;
     width: 100%;
     top: 84/$r;
     z-index: 99;
 }
.van-search__content {
    border-radius: 20px;
}

.exchange .van-tabs__line {
    width: 0.8533333333rem !important;
    height: 0.128rem;
    background: #FF6B29;
    border-radius: 0.064rem;
}
.exchange .van-badge--top-right{
  right: -10/$r;
}

</style>